@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/tailwind.css';


@layer base {
  @font-face {
    font-family: Avenir;
    src: url(../public/fonts/Avenir-Regular.ttf);
  }

  html {
    font-family: 'Avenir', 'Work Sans', sans-serif !important;
  }

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
}

td {
  max-width: 200px; /* Adjust the value based on your design */
  word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
}

thead {
   background-color: ghostwhite;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.ql-container.ql-snow {
  min-height: 120px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

